/* eslint-disable @eslint-community/eslint-comments/disable-enable-pair */
/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable vue/sort-keys */
import debounce from 'lodash/debounce'

import { FormBuilder } from '@/forms'
import notify from '@/mixins/notify'

/**
 *  Mixin to handle Menu Auto Saving
 *
 *  Array<{watchKey: string, [resourceKey]: string, [formBuilder]: boolean }>
 *
 */

const AutoSaveFormBuilder = (autoSaveFields, config) => {
  const dataFields = Object.keys(autoSaveFields).reduce(
    (acc, keyName) => ({
      ...acc,
      [keyName]: false,
    }),
    {}
  )

  return {
    mixins: [FormBuilder(autoSaveFields), notify],
    props: {
      /**
       * Is Loading
       */
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        mas_fields: autoSaveFields,
        mas_init: dataFields,
      }
    },
    watch: {
      ...Object.keys(autoSaveFields).reduce((acc, curr) => {
        const watchValue = `${curr}.value`

        return {
          ...acc,
          [watchValue](updatedValue) {
            // Ignore the first model sync
            if (this.mas_init[curr]) {
              if (
                !this[curr] ||
                !this[curr].status ||
                (this[curr].status && this[curr].status.valid)
              ) {
                this.updateResource({
                  [curr]: updatedValue,
                })
              }
            } else {
              this.mas_init[curr] = true
            }
          },
        }
      }, {}),
      resourceId() {
        this.syncLocalResource()
      },
    },
    created() {
      this.syncLocalResource()
    },
    methods: {
      mas_reset() {
        for (const key of Object.keys(this.mas_fields)) {
          this.mas_init[key] = false
        }
      },
      notifyError() {
        throw new Error('`notifyError` function must be implemented')
      },
      async syncLocalResource() {
        const currentResource = await this.loadHandler()

        this.mas_reset()

        for (const key of Object.keys(this.mas_fields)) {
          this[key].value = currentResource[key]
        }
      },
      updateResource: debounce(async function (update) {
        await this.updateHandler(update)
      }, config.debounceTime),
    },
  }
}

export { AutoSaveFormBuilder }
