import { FormGroupBase } from '../components/FormGroupBase'

/**
 * Form Builder with model sync capabilities.  You can think of this as a "factory"
 * for a FormGroup with some extra bells and whistles (model syncing)
 * @param {object} rawFormControls - FormControls
 * @returns {object} ExtendedFormGroup - Mixin
 */
const FormBuilderModelSync = (rawFormControls) => {
  const formGroup = FormGroupBase(rawFormControls, true)

  return {
    beforeMount() {
      this.$_fbms_update_model()
      this.$_fbms_update_valid()
      this.$_fbms_update_errors()
    },
    computed: {
      ...formGroup.computed,
      $_fbms_value: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        },
      },
    },
    created() {
      if (this.value) this.setInitialFormValues(this.value)
    },
    data() {
      return {
        ...formGroup.data,
      }
    },
    methods: {
      $_fbms_update_errors() {
        const { formErrors } = this
        this.$emit('update:errors', formErrors)
      },
      $_fbms_update_model() {
        const { formValues } = this

        this.$_fbms_value = formValues
      },
      $_fbms_update_valid() {
        const { isFormValid } = this

        this.$emit('update:valid', isFormValid)
      },
      ...formGroup.methods,
    },
    props: {
      /**
       * Errors from form
       */
      errors: { default: () => [], type: Array },

      /**
       * Form Valid State
       */
      valid: { default: false, type: Boolean },
      /**
       * @model used by v-model
       */
      value: { default: () => ({}), type: Object },
    },
    watch: {
      ...formGroup.watch,
    },
  }
}

export { FormBuilderModelSync }
