/**
 * Validates if string is an phone
 *
 * Return Validation Result:
 * {
 * valid: Boolean,
 * error: String | null
 * }
 * @param {string} valueToValidate String To Determine If Phone Number
 * @returns {object} Validation Result
 */
const phone = (valueToValidate) => {
  let value = valueToValidate

  if (!value) {
    return {
      errors: ['Must be 10 digits long'],
      valid: false,
    }
  }

  if (value.startsWith('+')) {
    value = value.slice(2)
  }

  if (value.length < 10) {
    return {
      errors: ['Must be 10 digits long'],
      valid: false,
    }
  }

  if (value.length > 10) {
    return {
      errors: ['Must not be more than 10 digits'],
      valid: false,
    }
  }

  const re = /[0-9]{10}/

  if (!re.test(value)) {
    return {
      errors: ['Must contain no letters or special characters'],
      valid: false,
    }
  }

  return {
    errors: [],
    valid: true,
  }
}

export { phone }
