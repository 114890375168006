/**
 * Validates if string is an EIN, which is a 9-digit nunber in the form "XX-XXXXXXX"
 *
 * Return Validation Result:
 * {
 * valid: Boolean,
 * error: String | null
 * }
 * @param {string} valueToValidate String To Determine If EIN
 * @returns {object} Validation Result
 */
const ein = (valueToValidate) => {
  const re = /^\d{2}-\d{7}$/

  if (valueToValidate && !re.test(valueToValidate)) {
    return {
      errors: ['Must be in the format: ##-#######'],
      valid: false,
    }
  }

  return {
    errors: [],
    valid: true,
  }
}

export { ein }
